define([
  'dojox/app/utils/config',
  'dojox/json/ref',
  'scramble/env',
  'dojo/when',
  'dojox/app/utils/nls',
], (
  configUtils,
  json,
  env,
  when,
  nlsUtils,
) => {
  const isObject = value => value && value.constructor === Object;

  // The main difference between this and configUtils.configMerge
  // is that arrays in the source will overwrite the value of the same name
  // in the target. In addition, this implementation does not check for
  // and ignore "dunder" prefixes.

  const configMerge = (target, source) => {
    for (const name in source) {
      const targetValue = target[name];
      const sourceValue = source[name];
      if (isObject(targetValue) && isObject(sourceValue)) {
        configMerge(targetValue, sourceValue);
      } else {
        target[name] = sourceValue;
      }
    }
    return target;
  };

  const mapTranslations = ([sourceNls, destNls]) => {
    const destProto = Object.getPrototypeOf
      ? Object.getPrototypeOf(destNls)
      : destNls.__proto__;

    for (k in sourceNls) {
      if (k === 'root') continue;

      destProto[k] = sourceNls[k];
      destNls[k] = sourceNls[k];
    }

    if (sourceNls.root) {
      for (k in sourceNls.root) {
        destProto[k] = sourceNls.root[k];
        destNls[k] = sourceNls.root[k];
      }
    }
  };

  return function (baseConfig, scrambleClientConfig, recipeClientConfig) {
    when(env.whenDefined('appConfig'), (appConfig) => {
      const { flags } = appConfig;

      if (flags.integrationFieldsNls && flags.integrationFields) {
        nlsUtils({ nls: flags.integrationFieldsNls }).then((nls) => {
          env.get('flags').integrationFieldsNls = nls;
        });
      }

      Promise.all(
        Object.entries(env.getFlag('nlsOverrides', {})).map(([sourceMid, destMid]) => {
          if (sourceMid === '__parent') return Promise.resolve();
          return new Promise((resolve, reject) => {
            let loaded = 0;
            const results = {};
            [sourceMid, destMid].forEach(mid => {
              const clientMid = window.clientBundle && mid.match(/^client\//)
                ? mid.replace('dojo/i18n!', '')
                : mid;
              nlsUtils({ nls: clientMid }).then(mod => {
                loaded += 1;
                results[mid] = mod;
                if (loaded === 2) resolve([results[sourceMid], results[destMid]]);
              });
            });
          }).then(mapTranslations);
        }),
      ).then(() => {
        const recipeTranslations = appConfig.translations;

        if (!recipeTranslations) return;

        const dojoLocale = dojoConfig.locale;

        const locale = dojoLocale.length === 2
          ? Object.keys(recipeTranslations).find(rcpLocale => rcpLocale.slice(0, 2) === dojoLocale)
          : dojoLocale;

        const recipeTranslationsForLocale = recipeTranslations[locale];

        if (!recipeTranslationsForLocale) return;

        nlsUtils({ nls: 'scramble/nls/common' })
          .then(commonNls => mapTranslations([
            recipeTranslationsForLocale,
            commonNls,
          ]));
      });
    });

    return configMerge(
      configUtils.configMerge(
        typeof baseConfig === 'string' ? json.fromJson(baseConfig) : baseConfig,
        scrambleClientConfig,
      ),
      recipeClientConfig,
    );
  };
});
