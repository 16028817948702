define(
  ({
    copy: 'Kopioi',
    paste: 'Liitä',
    copyToAll: 'Kopioi kaikkiin',
    add_to_cart: 'Lisää kärryyn',
    addToCart: 'Lisää kärryyn',
    remove: 'Poista',
    added: 'Lisätty',
    dropped: 'Poistettu',
    units: 'Yksiköt',
    total: 'Yhteensä',
    subtotal: 'Välisumma',
    moveToShipment: 'Siirrä toimitukseen',
    chooseOption: 'Valitse vaihtoehto',
    availability_msg: 'Jotkin pyydetyt lukumäärät eivät ole mahdollisia toimituspäivänäsi',
    atsWarning: 'Tämä lukumäärä ei ole saatavilla %{availableOn}.',
    inventory_warehouse: 'Inventaariovarasto',
    notInCatalog: 'Tuotetta ei voida lisätä kärryyn',
    notInCatalogExplanation: 'Valitettavasti tuotteiden lisäys eri katalogien välillä ei ole mahdollista.',
    digital_market_notInCatalogExplanation: 'Kärryssäsi voi olla kerrallaan vain yhden brändin nimikkeitä',
    removeFromCartSuggestion: 'Tahtoisitko <link>tyhjentää kärrysi?</link>',
    digital_market_removeFromCartSuggestion: 'Voit <link>tyhjentää kärrysi</link> lisätäksesi tämän nimikkeen',
    emptyCart: 'Tyhjennä kärry',
    empty: 'Tyhjä',
    emptyConfirmation: 'Haluatko varmasti tyhjentää kärrysi?',
    available_on: 'Saatavilla',
    personalization: 'Kohdennus',
    personalize: 'Kohdenna',
    configuration: 'Määritykset',
    configure: 'Muokkaa',
    placement: 'Sijoitus',
    content: 'Sisältö',
    color: 'Väri',
    product_specification: 'Tuotteen tiedot',
    type: 'Tyyppi',
    logo: 'Logo',
    text: 'Teksti',
    text_and_logo: 'Teksti, logo',
    other: 'Muu',
    value_might_be_stale: 'Tämä arvo saattaa olla vanhentunut kärryysi tekemistäsi muutoksista johtuen. Laske ne uudelleen tallentaaksesi tilauksesi.',
    more_details: 'Lisätietoja',
    availability: 'Saatavuus',
    no_scheduled_availability: 'Ei ajastettua saatavuutta',
    not_available_until: 'Saatavilla aikaisintaan <date />',
    available_as_of: 'Saatavilla <date />',
    units_bundled_singular_label: '%{units} pakattu yksikkö',
    units_bundled_label: '%{units} pakattua yksikköä',
    add_bundle_to_cart: 'Lisää paketti kärryyn',
    bundles: 'Paketit',
    bundled: 'Paketoitu',
    product: 'Tuote',
    sizes_and_quantities: 'Koko / lukumäärä',
    units_singular_label: '%{units} yksikkö',
    units_label: '%{units} yksikköä',
    current_availability: 'Tämänhetkinen saatavuus',
    future_availability: 'Tuleva saatavuus',
    size: 'Koko',
    sku: 'Varastoyksikkö',
    availability_date: 'Saatavilla oleva päivämäärä',
    quantity: 'Lkm.',
    availability_subject_to_change: 'Saatavuus saattaa muuttua',
    yes: 'Kyllä',
    no: 'Ei',
    copy_to_all_confirmation_message: 'Haluatko varmasti kopioida tämän kokovälin kaikkiin alla oleviin tyyleihin?',
    no_configurator_data: 'Määrittäjän tietoja ei ole saatavilla',
    oops: 'Hups!',
    something_went_wrong: 'Jokin meni vikaan!',
    error_message: 'Älä huoli, olemme ottaneet yhteyttä tukeen kertoaksemme heille tästä häiriöstä!',
    customize_item: 'Muokkaa nimikettä',
    per_item_upgrades: 'Per nimikkeen päivitykset',
    number_of_items: 'Nimikkeiden lukumäärä',
    upgrades_total: 'Päivitysten kokonaismäärä',
    total_price_message: '* Kokonaissumma lasketaan kärryssäsi.',
    customizer_summary: 'Määritysten yhteenveto',
    save: 'Tallenna',
    field_required: '%{field} on pakollinen',
    review_configuration: 'Tarkista määritykset',
    review_configurations: 'Tarkista määritykset',
    dismiss: 'Ohita',
    review: 'Tarkista',
    cyrp_changed: 'Määrittämiesi tuotteiden komponenttien saatavuus on saattanut muuttua. Tarkista tuotteidesi tiedot voidaksesi jatkaa.',
    cyrp_review_warning: 'Lisäämiesi tuotteiden komponenttien saatavuus on saattanut muuttua. Tarkista tuotteidesi tiedot voidaksesi jatkaa.',
    quantity_not_available: 'Tätä komponenttia ei ole tällä hetkellä saatavissa pyydetyissä määrissä. Valitse varastossa oleva vaihtoehtoinen nimike.',
  }),
);
