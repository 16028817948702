define([
  'dojo/_base/lang',
  'dojo/_base/array'
], function(
  lang,
  array
) {

  var util = {
    // sorts arr by values of position, assumed numeric, reassigns all position 
    // values based on array index, returns arr, MODIFIES IN PLACE!
    normalizePositions: function(arr, startIdx) {
      startIdx = startIdx || 0;

      arr.sort(function(a, b) {
        return a.position - b.position;
      });

      array.forEach(arr, function(element, idx) {
        element.position = idx + startIdx;
      });

      return arr;
    },

    fieldsToObject: function(fieldsObj) {
      return Object.keys(fieldsObj).reduce((acc, key) => {
        const dotKey = key.replace(/\]\[/g, '.').replace(/\[/g, '.').replace(/\]$/, '');
        dojo.setObject(dotKey, fieldsObj[key], acc);
        return acc;
      }, {});
    }
    //fieldsToObject takes an object like:
    // {
    //   user[first_name]: 'jon',
    //   user[last_name]: 'eckles'
    //   address[zip]: 95616
    // }
    //and returns
    // {
    //   user {
    //     first_name: 'jon',
    //     last_name: 'eckles'
    //   }
    //   address: {
    //     zip: 95616
    //   }
    // }
  };

  return util;
});
