define(
  ({
    copy: 'Copiar',
    paste: 'Colar',
    copyToAll: 'Copiar para tudo',
    add_to_cart: 'Adicionar ao carrinho',
    addToCart: 'Adicionar ao carrinho',
    remove: 'Remover',
    added: 'ADicionado',
    dropped: 'Abandonado',
    units: 'Unidades',
    total: 'Total',
    subtotal: 'Subtotal',
    moveToShipment: 'Mover para envio',
    chooseOption: 'Escolha uma opção',
    availability_msg: 'Algumas das quantidades solicitadas não estão disponíveis na data do envio que solicitou',
    atsWarning: 'Esta quantidade não estará disponível em %{availableOn}.',
    inventory_warehouse: 'Inventário no armazém',
    notInCatalog: 'Impossível adicionar item ao carrinho',
    notInCatalogExplanation: 'Lamentamos, mas adicionar itens de diferentes catálogos não é possível.',
    digital_market_notInCatalogExplanation: 'O seu carrinho só pode ter itens de uma marca de cada vez',
    removeFromCartSuggestion: 'Pretende <link>esvaziar o seu carrinho?</link>',
    digital_market_removeFromCartSuggestion: 'Pode <link>esvaziar o seu carrinho</link> para adicionar este item',
    emptyCart: 'Esvaziar carrinho',
    empty: 'Esvaziar',
    emptyConfirmation: 'Tem a certeza de que pretende esvaziar o seu carrinho?',
    available_on: 'Disponível a',
    personalization: 'Personalização',
    personalize: 'Personalizar',
    configuration: 'Configuração',
    configure: 'Personalizar',
    placement: 'Colocação',
    content: 'Conteúdo',
    color: 'Cor',
    product_specification: 'Detalhes do produto',
    type: 'Tipo',
    logo: 'Logótipo',
    text: 'Texto',
    text_and_logo: 'Texto, logótipo',
    other: 'Outro',
    value_might_be_stale: 'Por motivos de alteração ao seu carrinho, este valor pode não estar atualizado. Guarde a sua encomenda para voltar a calcular.',
    more_details: 'Descrição',
    availability: 'Disponibilidade',
    no_scheduled_availability: 'Não há uma previsão da disponibilidade',
    not_available_until: 'Indisponível até <date />',
    available_as_of: 'Disponível a partir de <date />',
    units_bundled_singular_label: '%{units} unidade agrupada',
    units_bundled_label: '%{units} unidades agrupadas',
    add_bundle_to_cart: 'Adicionar pacote ao carrinho',
    bundles: 'Pacotes',
    bundled: 'Agrupado',
    product: 'Produto',
    sizes_and_quantities: 'Tamanho / Quantidade',
    units_singular_label: '%{units} unidade',
    units_label: '%{units} unidades',
    current_availability: 'Disponibilidade atual',
    future_availability: 'Disponibilidade futura',
    size: 'Tamanho',
    sku: 'SKU',
    availability_date: 'Data da disponibilidade',
    quantity: 'Qntd.',
    availability_subject_to_change: 'Disponibilidade sujeita a alterações',
    yes: 'Sim',
    no: 'Não',
    copy_to_all_confirmation_message: 'Tem a certeza de que pretende copiar esta gama de tamanhos para todos os estilos abaixo?',
    no_configurator_data: 'Não está disponível qualquer dado do Configurador',
    oops: 'Ups!',
    something_went_wrong: 'Aconteceu algo de errado!',
    error_message: 'Não se preocupe, já contactámos o apoio para informar deste erro!',
    customize_item: 'Personalizar item',
    per_item_upgrades: 'Atualizações por item',
    number_of_items: 'Número de itens',
    upgrades_total: 'Total de atualizações',
    total_price_message: '* O preço total será calculado no seu carrinho.',
    customizer_summary: 'Resumo da configuração',
    save: 'Guardar',
    field_required: '%{field} é obrigatório',
    review_configuration: 'Rever a configuração',
    review_configurations: 'Rever configurações',
    dismiss: 'Ignorar',
    review: 'Rever',
    cyrp_changed: 'A disponibilidade de componentes no(s) seu(s) produto(s) configurado(s) pode ter sofrido alterações. Reveja a configuração do(s) seu(s) produto(s).',
    cyrp_review_warning: 'A disponibilidade de componentes no(s) seu(s) produto(s) configurado(s) pode ter sofrido alterações. Reveja a configuração do(s) seu(s) produto(s) para continuar.',
    quantity_not_available: 'Este componente está, de momento, indisponível na quantidade que solicitou. Por favor, escolha um item alternativo que haja em stock.',
  }),
);
