define(
  ({
    copy: 'Copia',
    paste: 'Incolla',
    copyToAll: 'Copia Tutto',
    add_to_cart: 'Aggiungi al carrello',
    addToCart: 'Aggiungi al Carrello',
    remove: 'Rimuovi',
    added: 'Aggunto',
    dropped: 'Eliminato',
    units: 'Unità',
    total: 'Totale',
    subtotal: 'Subtotale',
    moveToShipment: 'Sposta a spedizione',
    chooseOption: "Scegli un'opzione",
    availability_msg: 'Alcune quantità richieste non sono disponibili nella data di spedizione',
    atsWarning: 'Questa quantità non sarà disponibile fino a %{availableOn}.',
    inventory_warehouse: 'Inventario magazzino',
    notInCatalog: 'Impossibile aggiungere al carrello',
    notInCatalogExplanation: 'Spiacenti, ma non è possibile aggiungere articoli tra cataloghi.',
    digital_market_notInCatalogExplanation: 'Il tuo carrello contiene solo articoli di un brand per volta',
    removeFromCartSuggestion: 'Desideri <link>svuotare il carrello?</link>',
    digital_market_removeFromCartSuggestion: 'Puoi <link>svuotare il carrello</link> per aggiungere questo articolo',
    emptyCart: 'Svuota il carrello',
    empty: 'Svuota',
    emptyConfirmation: 'Vuoi davvero svuotare il carrello?',
    available_on: 'Disponibile il',
    personalization: 'Personalizzazione',
    personalize: 'Personalizza',
    configuration: 'Configurazione',
    configure: 'Personalizza',
    placement: 'Posizionamento',
    content: 'Contenuto',
    color: 'Colore',
    product_specification: 'Specifiche del prodotto',
    type: 'Tipo',
    logo: 'Logo',
    text: 'Testo',
    text_and_logo: 'Testo, Logo',
    other: 'Altro',
    value_might_be_stale: "A causa delle modifiche nel tuo carrello, questo valore potrebbe non essere più valido. Salva l'ordine per ricalcolare.",
    more_details: 'Altri dettagli',
    availability: 'Disponibilità',
    no_scheduled_availability: 'Nessuna disponibilità programmata',
    not_available_until: 'Non disponibile fino al <date />',
    available_as_of: 'Disponibile dal <date />',
    units_bundled_singular_label: '%{units} unità raggruppate',
    units_bundled_label: '%{units} unità raggruppate',
    add_bundle_to_cart: 'Aggiungi pacchetto al carrello',
    bundles: 'Pacchetti',
    bundled: 'Raggruppato',
    product: 'Prodotto',
    sizes_and_quantities: 'Dimensioni/Quantità',
    units_singular_label: '%{units} unità',
    units_label: '%{units} unità',
    current_availability: 'Disponibilità attuale',
    future_availability: 'Disponibilità futura',
    size: 'Taglia',
    sku: 'SKU',
    availability_date: 'Data di disponibilità',
    quantity: 'Qtà',
    availability_subject_to_change: 'La disponibilità può variare',
    yes: 'Sì',
    no: 'No',
    copy_to_all_confirmation_message: 'Vuoi davvero copiare questo intervallo di taglie a tutti gli stili qui sotto?',
    no_configurator_data: 'Nessun dato del configuratore è disponibile',
    oops: 'Oops!',
    something_went_wrong: 'Qualcosa è andato storto!',
    error_message: "Non preoccuparti, abbiamo contattato l'assistenza per comunicare l'errore.",
    customize_item: 'Personalizza articolo',
    per_item_upgrades: 'Aggiornamenti per articolo',
    number_of_items: 'Numero di articoli',
    upgrades_total: 'Aggiornamenti totali',
    total_price_message: '* Il prezzo totale sarà calcolato nel tuo carrello.',
    customizer_summary: 'Riepilogo della configurazione',
    save: 'Salva',
    field_required: '%{field} è obbligatorio',
    review_configuration: 'Rivedi configurazione',
    review_configurations: 'Rivedi configurazioni',
    dismiss: 'Annulla',
    review: 'Rivedi',
    cyrp_changed: 'La disponibilità dei componenti nei prodotti configurati potrebbe essere cambiata. Controlla le configurazioni dei prodotti.',
    cyrp_review_warning: 'La disponibilità dei componenti nei prodotti configurati potrebbe essere cambiata. Controlla le configurazioni dei prodotti per continuare.',
    quantity_not_available: 'Questo componente non è al momento disponibile nella quantità richiesta. Seleziona un articolo alternativo in stock.',
  }),
);
