define(
  ({
    app_name: 'Elastic',
    none: 'Ingen',
    select: 'velg …',
    cancel: 'Avbryt',
    drop_ship_instructions: 'Bare innenriks frakt. Ingen frakt til APO-er eller FPO-er.',
    product_data_sheet_tab_technology: 'Teknologi',
    product_data_sheet_tab_overview: 'Oversikt',
    product_data_sheet_tab_details: 'Detaljer',
    cart_submit_order_dealer: 'Send bestilling',
    cart_submit_order_rep: 'Send bestilling',
    cart_submit_order: 'Send bestilling',
    erp_order_number: 'ERP-bestillingsnummer',
    sap_order_number: 'SAP-bestillingsnummer',
    nda_explanation: 'NDA-forklaring.',
    terms_and_conditions_description: 'Sjekk nedenfor for å indikere at du har lest og godtar <a href="###" target="_blank">vilkårene og betingelsene</a>',
    discounted_total: 'Total rabatt',
    upcharge: 'Ekstra betaling',
    dont_show_me_this_again: 'Ikke vis meg dette igjen.',
    okay: 'Ok',
    arrival_date: 'Start for leveringsdato',
    sso_message: 'Ekstern pålogging',
    alt_login_title: 'Logg på',
    prebook: 'Forhåndsbestill',
    ats: 'ATS',
    alternate_images: 'Alternative bilder',
    choose: 'Velg',
    new_version_available_modal: 'Ny versjon tilgjengelig',
    new_version_available_message: 'En ny versjon av appen er tilgjengelig',
    new_version_reload: 'Last inn ny versjon',
    new_version_snooze: 'Minn meg på det senere',
    new_version_save_and_reload: 'Lagre arbeidet mitt og last på nytt',
    popup_may_be_suppressed_title: 'Betalingssiden åpnet',
    popup_may_be_suppressed_description: 'En kredittkortbetalingsside er åpnet. Hvis du ikke ser den, sjekk adressefeltet i nettleseren din for å se om det er en blokkert pop-up, og hvis det er tilfelle, tillat den.',
    popup_blocked_title: 'Pop-up blokkert',
    popup_blocked_description: 'Det nye vinduet for innsending av betaling ble blokkert. Sjekk nettleserinnstillingene og utvidelsene dine, og prøv på nytt. Hvis problemet vedvarer, kan du lagre bestillingen og åpne Elastic i en annen nettleser. Elastic støttes best i nyere versjoner av Google Chrome.',
    pay_by_credit_card: 'Betal med kredittkort',
    do_you_want_to_pay_by_credit_card: 'Vil du betale med kredittkortet ditt?',
    pay_by_credit_card_no: 'Nei',
    pay_by_credit_card_yes: 'Ja',
    cancel_order_submission_and_payment: 'Avbryt bestilling og betaling',
    product_reference_short: 'REF.: %{reference}',
    product_reference_long: 'Forrige års SKU: %{reference}',
    variation_code: 'Variasjonskode',
    pending_orders: 'Ventende bestillinger',
    freight_discount_message: 'Varer i denne forsendelsen kvalifiserer for fraktrabatten %{sales_program}',
    partial_freight_discount_message: 'Velg en fraktmetode for varer i denne forsendelsen som ikke kvalifiserer for fraktrabatten %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Varer som ikke kvalifiserte for %{sales_program} sendes via standardmetoden',
    export_pdf: 'Eksporter PDF',
    ots: 'OTS',
    terms_and_conditions: 'Vilkår',
    accept: 'Godta',
    back_to_orders: 'Tilbake til bestillinger',
    back_to_payments: 'Tilbake til betalinger',
    back_to_shipments: 'Tilbake til forsendelser',
    required: 'Obligatorisk',
    send_request: 'send forespørsel',
    should_not_empty: 'kan ikke være tomt',
    should_be_number: 'må være et tall',
    incorrect_value: 'feil verdi',
    incorrect_phone: 'telefonnummeret må samsvare med det internasjonale formatet +xx',
    board_riders_description: 'Boardriders, Inc., er et ledende actionsport- og livsstilsselskap som designer, produserer og distribuerer merkeklær, fottøy og tilbehør til Boardriders over hele verden. Selskapets kles- og fottøymerker representerer en uformell livsstil for yngre mennesker som er inspirert av en lidenskap for utendørs actionsport.',
    region_error_message: 'må velge region',
    country_error_message: 'må velge land',
    state_error_message: 'må velge stat/provins',
    config_error_message: 'Det oppsto en feil ved åpning av dette skjemaet på grunn av manglende administrasjonsinnstillinger.',
    success: 'Suksess',
    error: 'Feil',
    invalid_phone_number: 'Ugyldig telefonnummer',
    invalid_email: 'Ugyldig e-post',
    submit: 'Send',
    success_message: 'Informasjonen din ble sendt',
    error_message: 'Det oppstod en feil under sending av informasjonen. Prøv igjen eller kontakt kundestøtte hvis problemet vedvarer.',
    no_schema: 'Denne funksjonen er ikke konfigurert',
    reset: 'Tilbakestill',
    quick_entry_table_header_shipment_b2c: 'Forsendelse',
    enter_quantities_segmented_control_b2c_by_shipment: 'Ved forsendelse',
    new_document_b2c_shipment: 'Forsendelse',
    boxed_order: 'Bokset bestilling',
    boxed_order_explanation_title: 'Boksede bestillinger',
    boxed_order_explanation: 'Å legge til mer enn én enkelt boks til en ordre fungerer som en mengdemultiplikator. Angitte mengder blir duplisert på tvers av antall bokser som legges inn.',
    boxes: 'Bokser',
    units_per_box: 'Enheter per boks',
    regional: 'Regional',
    purchase_order_tooltip: 'Den angitte verdien er ikke gyldig.',
    tournament_date_is_too_late: 'Datoen må være %{date} eller tidligere',
    tournament_date_is_too_early: 'Datoen må være på eller etter %{date}',
    page_notes_error: 'Den angitte verdien er ikke gyldig.',
    order_name_not_valid: 'Den angitte verdien er ikke gyldig.',
    internet_connection_lost: 'Internettforbindelse mistet',
    internet_connection_lost_builder_description: '<p>Du er ikke koblet til internett. Koble til på nytt for å fortsette. Arbeidet ditt har blitt lagret opp til den siste manuelle eller automatisk lagringen.</p><p>Hvis du er bekymret for tap av data, vennligst klikk på knappen nedenfor for å laste ned en sikkerhetskopidatafil, og videresend filen til <Link> support@elasticsuite.com</Link> for å gjenopprette bestillingen din.</p>',
    internet_connection_lost_description: 'Du er i øyeblikket ikke koblet til internett. Koble til igjen for å fortsette.',
    download_backup_data_file: 'Last ned sikkerhetskopidatafil',
    invalid_session: 'Ugyldig økt',
    invalid_session_builder_description: '<p>Du er ikke koblet til internett. Koble til på nytt for å fortsette. Arbeidet ditt har blitt lagret opp til den siste manuelle eller automatisk lagringen.</p><p>Hvis du er bekymret for tap av data, kan du klikke på knappen nedenfor for å laste ned en sikkerhetskopidatafil og videresende filen til <Link> support@elasticsuite.com</Link> for å gjenopprette bestillingen din.</p>',
    invalid_session_description: 'Du er i øyeblikket ikke koblet til internett. Koble til igjen for å fortsette.',
    submit_order_description: 'Bestillingen din er i ferd med å bli sendt inn. Når den er sendt inn, blir den låst og kan ikke lenger redigeres. Vær oppmerksom på at bestillingen lagres i skyen.',
    all_sells_final: 'Alle arrangementsbestillinger er endelige og kan ikke returneres. Sørg for at du har sjekket at alt er rett med bestillingen din før du sender inn. Takk for hjelpen!',
    invalid_date: 'Ugyldig dato',
    date_placeholder: 'MM-DD-ÅÅÅÅ',
  }),
);
