define(
  ({
    app_name: 'Elastic',
    none: 'Ninguno',
    select: 'seleccionar...',
    cancel: 'Cancelar',
    drop_ship_instructions: 'Solo envío doméstico. No se realizarán envíos militares o navales.',
    product_data_sheet_tab_technology: 'Tecnología',
    product_data_sheet_tab_overview: 'Descripción',
    product_data_sheet_tab_details: 'Detalles',
    cart_submit_order_dealer: 'Realizar pedido',
    cart_submit_order_rep: 'Realizar pedido',
    cart_submit_order: 'Enviar pedido',
    erp_order_number: 'Pedido ERP #',
    sap_order_number: '# Pedido SAP',
    nda_explanation: 'Explicación de NDA',
    terms_and_conditions_description: 'Mira abajo para indicar que has leído y aceptas los <a href="###" target="_blank">Términos & Condiciones</a>',
    discounted_total: 'Total con descuento',
    upcharge: 'Recargo',
    dont_show_me_this_again: 'No volver a mostrármelo',
    okay: 'Vale',
    arrival_date: 'Fecha de inicio de envío',
    sso_message: 'Inicio de sesión externo',
    alt_login_title: 'Iniciar sesión',
    prebook: 'Pre Reserva',
    ats: 'ATS',
    alternate_images: 'Imágenes alternativas',
    choose: 'Elegir',
    new_version_available_modal: 'Nueva versión disponible',
    new_version_available_message: 'Hay una nueva versión de la aplicación disponible',
    new_version_reload: 'Cargar nueva versión',
    new_version_snooze: 'Recuérdamelo más tarde',
    new_version_save_and_reload: 'Guardar mi trabajo y recargar',
    popup_may_be_suppressed_title: 'Página de pago abierta',
    popup_may_be_suppressed_description: 'Se ha abierto una página de pago con tarjeta de crédito. Si no la ves, comprueba la barra de dirección de tu navegador por si se ha bloqueado la ventana emergente y permítela.',
    popup_blocked_title: 'Ventana emergente bloqueada',
    popup_blocked_description: 'Se ha bloqueado la nueva página para enviar el pago. Comprueba los ajustes de tu navegador y las extensiones y vuelve a intentarlo. Si el problema persiste, puedes guardar tu pedido y abrir Elastic en otro navegador. Elastic funciona mejor en las últimas versiones de Google Chrome.',
    pay_by_credit_card: 'Pagar con tarjeta de crédito',
    do_you_want_to_pay_by_credit_card: '¿Quieres pagar con tarjeta de crédito?',
    pay_by_credit_card_no: 'No',
    pay_by_credit_card_yes: 'Sí',
    cancel_order_submission_and_payment: 'Cancelar envío y pago de pedido',
    product_reference_short: 'REF: %{reference}',
    product_reference_long: 'SKU de año anterior: %{reference}',
    variation_code: 'Código de variación',
    pending_orders: 'Pedidos pendientes',
    freight_discount_message: 'Hay artículos en este envío a los que se les puede aplicar el descuento en el envío de %{sales_program}',
    partial_freight_discount_message: 'Elige un método de envío para los artículos de este envío a los que no se les puede aplicar el descuento en el envío de %{sales_program}',
    non_qualifying_items_ship_by_default_method: 'Los artículos a los que no se les puede aplicar el descuento de %{sales_program} se enviarán a través del método por defecto',
    export_pdf: 'Exportar PDF',
    ots: 'OTS',
    terms_and_conditions: 'Términos y condiciones',
    accept: 'Aceptar',
    back_to_orders: 'Volver a los pedidos',
    back_to_payments: 'Volver a los pagos',
    back_to_shipments: 'Volver a los envíos',
    required: 'Obligatorio',
    send_request: 'enviar solicitud',
    should_not_empty: 'no debería estar vacío',
    should_be_number: 'debe ser un número',
    incorrect_value: 'valor incorrecto',
    incorrect_phone: 'el número de teléfono debe tener el formato internacional +xx',
    board_riders_description: 'Boardriders, Inc., es una empresa líder en deportes de acción y estilo de vida que diseña, produce y distribuye ropa, calzado y accesorios de la marca Boardriders en todo el mundo. Las marcas de ropa y calzado de la empresa representan un estilo de vida informal para gente de mentalidad joven y están inspiradas en la pasión por los deportes de acción al aire libre.',
    region_error_message: 'tienes que seleccionar una región',
    country_error_message: 'tienes que seleccionar un país',
    state_error_message: 'tienes que seleccionar un estado/una provincia',
    config_error_message: 'Se ha producido un error al abrir este formulario porque faltan ajustes de administración.',
    success: 'Operación correcta',
    error: 'Error',
    invalid_phone_number: 'Numero de teléfono no válido',
    invalid_email: 'correo electrónico no válido',
    submit: 'Enviar',
    success_message: 'Tu información se ha enviado correctamente',
    error_message: 'Ha habido un error al enviar tu información. Inténtalo de nuevo o ponte en contacto con el servicio de asistencia si el problema persiste.',
    no_schema: 'Esta función no se ha configurado',
    reset: 'Restablecer',
    quick_entry_table_header_shipment_b2c: 'Envío',
    enter_quantities_segmented_control_b2c_by_shipment: 'Por envío',
    new_document_b2c_shipment: 'Envío',
    boxed_order: 'Pedido por cajas',
    boxed_order_explanation_title: 'Pedido por cajas',
    boxed_order_explanation: 'Agregar más de una caja a un pedido actúa como multiplicador de cantidad. Las cantidades pedidas se replicarán por cada una de las cajas pedidas.',
    boxes: 'Cajas',
    units_per_box: 'Unidades por caja',
    regional: 'Regional',
    purchase_order_tooltip: 'El valor que has introducido no es válido.',
    tournament_date_is_too_late: 'La fecha debe ser anterior al %{date}',
    tournament_date_is_too_early: 'La fecha debe ser posterior al %{date}',
    page_notes_error: 'El valor introducido no es válido',
    order_name_not_valid: 'El valor introducido no es válido.',
    internet_connection_lost: 'Se ha perdido la conexión a Internet',
    internet_connection_lost_builder_description: '<p>No estás conectado a Internet. Vuelve a conectarte para continuar. Se ha guardado tu trabajo hasta el último guardado manual o automático.</p><p>Si te preocupa perder los datos, haz clic en el siguiente botón para descargar el archivo de los datos de la copia de seguridad, y reenvía el archivo a <Link>support@elasticsuite.com</Link> para restaurar tu pedido.</p>',
    internet_connection_lost_description: 'Actualmente no estás conectado a Internet. Vuelve a conectarte para continuar.',
    download_backup_data_file: 'Descargar el archivo de los datos de la copia de seguridad',
    invalid_session: 'Sesión no válida',
    invalid_session_builder_description: '<p>No estás conectado a Internet. Vuelve a conectarte para continuar. Se ha guardado tu trabajo hasta el último guardado manual o automático.</p><p>Si te preocupa perder los datos, haz clic en el siguiente botón para descargar el archivo de los datos de la copia de seguridad, y reenvía el archivo a <Link>support@elasticsuite.com</Link> para restaurar tu pedido.</p>',
    invalid_session_description: 'Actualmente no estás conectado a Internet. Vuelve a conectarte para continuar.',
    submit_order_description: 'Tu pedido está a punto de ser procesado. Una vez procesado, ya no podrá modificarse. Recuerda que el pedido se guardará en la nube.',
    all_sells_final: 'Todos los pedidos de eventos son finales y no elegibles para ser devueltos. Asegúrate de haber revisado tu pedido antes de enviarlo. ¡Gracias por tu colaboración!',
    invalid_date: 'Fecha no válida',
    date_placeholder: 'MM-DD-AAAA',
  }),
);
