define(
  ({
    copy: 'Copier',
    paste: 'Coller',
    copyToAll: 'Copier vers tous',
    add_to_cart: 'Ajouter au panier',
    addToCart: 'Ajouter au panier',
    remove: 'Retirer',
    added: 'Ajouté',
    dropped: 'Retiré',
    units: 'Unités',
    total: 'Total',
    subtotal: 'Sous-total',
    moveToShipment: "Déplacer vers l'expédition",
    chooseOption: 'Choisir une option',
    availability_msg: "Certaines quantités demandées sont indisponibles à votre date d'expédition",
    atsWarning: "Cette quantité ne sera pas disponible d'ici le %{availableOn}.",
    inventory_warehouse: "Entrepôt d'inventaire",
    notInCatalog: "Impossible d'ajouter l'article au panier",
    notInCatalogExplanation: "Désolé, mais l'ajout d'articles à partir de plusieurs catalogues n'est pas disponible.",
    digital_market_notInCatalogExplanation: "Votre panier ne peut contenir que des articles d'une seule marque à la fois",
    removeFromCartSuggestion: 'Voulez-vous <link>vider votre panier ?</link>',
    digital_market_removeFromCartSuggestion: 'Vous pouvez <link>vider votre panier</link> pour ajouter cet article',
    emptyCart: 'Panier vide',
    empty: 'Vide',
    emptyConfirmation: 'Voulez-vous vraiment vider votre panier ?',
    available_on: 'Disponible sur',
    personalization: 'Personnalisation',
    personalize: 'Personnaliser',
    configuration: 'Configuration',
    configure: 'Personnaliser',
    placement: 'Placement',
    content: 'Contenu',
    color: 'Couleur',
    product_specification: 'Spécifications du produit',
    type: 'Type',
    logo: 'Logo',
    text: 'Texte',
    text_and_logo: 'Texte, logo',
    other: 'Autre',
    value_might_be_stale: 'En raison de changements dans votre panier, cette valeur est peut-être obsolète. Enregistrez votre commande pour recalculer.',
    more_details: 'Plus de détails',
    availability: 'Disponibilité',
    no_scheduled_availability: 'Aucune disponibilité programmée',
    not_available_until: "Non disponible jusqu'au <date />",
    available_as_of: 'Disponible à partir du <date />',
    units_bundled_singular_label: '%{units} unité groupée',
    units_bundled_label: '%{units} unités groupées',
    add_bundle_to_cart: 'Ajouter le lot au panier',
    bundles: 'Lots',
    bundled: 'Groupé',
    product: 'Produit',
    sizes_and_quantities: 'Taille / Quantité',
    units_singular_label: '%{units} unité',
    units_label: '%{units} unités',
    current_availability: 'Disponibilité Actuelle',
    future_availability: 'Disponibilité Future',
    size: 'Taille',
    sku: 'UGS',
    availability_date: 'Date de Disponibilité',
    quantity: 'Qté.',
    availability_subject_to_change: 'La disponibilité est sujette à changement',
    yes: 'Oui',
    no: 'Non',
    copy_to_all_confirmation_message: 'Voulez-vous vraiment copier cette gamme de tailles dans tous les styles ci-dessous ?',
    no_configurator_data: "Aucune donnée de configurateur n'est dsp",
    oops: 'Oups !',
    something_went_wrong: 'Une erreur est survenue !',
    error_message: "Ne vous inquiétez pas, nous avons contacté l'assistance pour l'informer de cette erreur !",
    customize_item: "Personnaliser l'article",
    per_item_upgrades: 'Mises à niveau par article',
    number_of_items: "Nombre d'articles",
    upgrades_total: 'Total des mises à niveau',
    total_price_message: '* Le prix total sera calculé dans votre panier.',
    customizer_summary: 'Résumé de la configuration',
    save: 'Enregistrer',
    field_required: '%{field} est obligatoire',
    review_configuration: 'Examiner la configuration',
    review_configurations: 'Examiner les configurations',
    dismiss: 'Annuler',
    review: 'Examiner',
    cyrp_changed: 'La disponibilité des composants de votre ou vos produits configurés a peut-être changé. Examinez la ou les configurations de vos produits.',
    cyrp_review_warning: 'La disponibilité des composants de votre ou vos produits configurés a peut-être changé. Examinez la ou les configurations de vos produits pour continuer.',
    quantity_not_available: 'Ce composant est actuellement indisponible avec la quantité demandée. Veuillez sélectionner un autre article en stock.',
  }),
);
